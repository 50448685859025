import {
	ACQUIRE_DATA_BEGIN,
	ACQUIRE_DATA_SUCCESS,
	ACQUIRE_DATA_ERROR,
	DISPLAY_ALERT,
	CLEAR_ALERT,
	CLEAR_VALUES,
	GET_TESTS_BEGIN,
	GET_TESTS_SUCCESS,
	GET_TEST_TYPES_BEGIN,
	GET_TEST_TYPES_SUCCESS,
	SAVE_TEST_BEGIN,
	SAVE_TEST_SUCCESS,
	SAVE_TEST_ERROR,
	SHOW_REPORT_BEGIN,
	SHOW_REPORT_SUCCESS,
	HANDLE_CHANGE,
} from "./actions";

const reducer = (state, action) => {
	if (action.type === DISPLAY_ALERT) {
		return {
			...state,
			showAlert: true,
			alertType: "danger",
			alertText: "Please provide all values!",
		};
	}
	if (action.type === CLEAR_ALERT) {
		return {
			...state,
			showAlert: false,
			alertType: "",
			alertText: "",
		};
	}

	if (action.type === ACQUIRE_DATA_BEGIN) {
		return { ...state, isLoading: true, showAlert: false };
	}
	if (action.type === ACQUIRE_DATA_SUCCESS) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			// data: action.payload.data,
			alertType: "success",
			alertText: "Data Acquisition Completed! Redirecting...",
		};
	}
	if (action.type === ACQUIRE_DATA_ERROR) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: "danger",
			alertText: action.payload.msg,
		};
	}

	if (action.type === SAVE_TEST_BEGIN) {
		return { ...state, isLoading: true };
	}

	if (action.type === SAVE_TEST_SUCCESS) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: "success",
			alertText: "Test Saved",
		};
	}
	if (action.type === SAVE_TEST_ERROR) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: "danger",
			alertText: action.payload.msg,
		};
	}

	if (action.type === CLEAR_VALUES) {
		const initialState = {
			isEditing: false,
			recordingId: "",
			testId: "",
			firstName: "",
			lastName: "",
			birthDate: "",
			gender: "",
			testType: "",
			eye: "",
		};

		return {
			...state,
			...initialState,
		};
	}

	if (action.type === GET_TESTS_BEGIN) {
		return { ...state, isLoading: true, showAlert: false };
	}
	if (action.type === GET_TESTS_SUCCESS) {
		return {
			...state,
			isLoading: false,
			tests: action.payload.tests,
		};
	}

	if (action.type === GET_TEST_TYPES_BEGIN) {
		return { ...state, isLoading: true, showAlert: false };
	}
	if (action.type === GET_TEST_TYPES_SUCCESS) {
		return {
			...state,
			isLoading: false,
			testTypes: action.payload.testTypes,
		};
	}

	if (action.type === SAVE_TEST_BEGIN) {
		return { ...state, isLoading: true };
	}

	if (action.type === SAVE_TEST_SUCCESS) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: "success",
			alertText: "Test Saved",
		};
	}
	if (action.type === SAVE_TEST_ERROR) {
		return {
			...state,
			isLoading: false,
			showAlert: true,
			alertType: "danger",
			alertText: action.payload.msg,
		};
	}

	if (action.type === SHOW_REPORT_BEGIN) {
		return { ...state, isLoading: true };
	}
	if (action.type === SHOW_REPORT_SUCCESS) {
		return { ...state, isLoading: false };
	}

	if (action.type === HANDLE_CHANGE) {
		return { ...state, isLoading: false };
	}

	throw new Error(`no such action : ${action.type}`);
};

export default reducer;
