import React, { useReducer, useContext, useEffect, useCallback } from "react";

import { formatDOB } from "../helpers.js";

import reducer from "./reducer";
import axios from "axios";
import {
	ACQUIRE_DATA_BEGIN,
	ACQUIRE_DATA_SUCCESS,
	ACQUIRE_DATA_ERROR,
	SETUP_USER_BEGIN,
	SETUP_USER_SUCCESS,
	SETUP_USER_ERROR,
	CLEAR_ALERT,
	CLEAR_VALUES,
	DISPLAY_ALERT,
	GET_TESTS_BEGIN,
	GET_TESTS_SUCCESS,
	GET_TEST_TYPES_BEGIN,
	GET_TEST_TYPES_SUCCESS,
	SAVE_TEST_BEGIN,
	SAVE_TEST_SUCCESS,
	SAVE_TEST_ERROR,
	SHOW_REPORT_BEGIN,
	SHOW_REPORT_SUCCESS,
	HANDLE_CHANGE,
} from "./actions.js";

const initialState = {
	userLoading: true,
	isLoading: false,
	showAlert: false,
	alertText: "",
	alertType: "",
	tests: [],
	testTypes: [],
	numOfPages: 1,
	page: 1,
	testId: "",
	firstName: "",
	lastName: "",
	birthDate: "",
	gender: "",
	testType: "",
	eye: "",
	createdBy: "",
	data: [],
	reportData: "",
	status: "",
};

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	// Test
	const testFetch = axios.create({
		baseURL: `${process.env.REACT_APP_SERVER_URI}/${process.env.REACT_APP_TEST_SERVICE_API_VERSION}/`,
		withCredentials: true,
	});

	testFetch.interceptors.request.use(
		(req) => {
			req.headers["Content-Type"] = "application/json; charset=utf-8";
			// Include the CSRF token in the request headers
			const csrfToken = localStorage.getItem("csrfToken");

			if (csrfToken) {
				req.headers["X-CSRF-Token"] = csrfToken;
			}
			return req;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	testFetch.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	// Report
	const reportFetch = axios.create({
		baseURL: `${process.env.REACT_APP_SERVER_URI}/${process.env.REACT_APP_REPORT_SERVICE_API_VERSION}/`,
		responseType: "blob",
		withCredentials: true,
	});

	reportFetch.interceptors.request.use(
		(req) => {
			req.headers["Content-Type"] = "application/json; charset=utf-8";
			req.headers["Accept"] = "*/*";
			return req;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	reportFetch.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			console.log(error.response);
			return Promise.reject(error);
		}
	);

	// Data Acquire
	const dataAcquire = axios.create({
		baseURL: `${process.env.REACT_APP_SERVER_URI}/${process.env.REACT_APP_DATA_ACQUISITION_API_VERSION}/`,
		withCredentials: true,
	});

	dataAcquire.interceptors.request.use(
		(req) => {
			req.headers["Content-Type"] = "application/json; charset=utf-8";
			req.headers["Accept"] = "*/*";
			return req;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	dataAcquire.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			console.log(error.response);
			return Promise.reject(error);
		}
	);

	const displayAlert = () => {
		dispatch({ type: DISPLAY_ALERT });
		clearAlert();
	};

	const clearAlert = () => {
		setTimeout(() => {
			dispatch({ type: CLEAR_ALERT });
		}, 3000);
	};

	const handleChange = ({ name, value }) => {
		dispatch({ type: HANDLE_CHANGE, payload: { name, value } });
	};

	const clearValues = () => {
		dispatch({ type: CLEAR_VALUES });
	};

	const acquireData = async (testId, trajectoryName) => {
		dispatch({ type: ACQUIRE_DATA_BEGIN });
		try {
			let url = `/data/acquire/${testId}/${trajectoryName}`;
			const { data } = await dataAcquire.get(url);
			const { recordingId } = data;

			dispatch({
				type: ACQUIRE_DATA_SUCCESS,
				payload: { data },
			});
		} catch (error) {
			console.log(error.response);
		}
	};

	const saveTest = async (testData) => {
		dispatch({ type: SAVE_TEST_BEGIN });
		try {
			const {
				testId,
				firstName,
				lastName,
				dateOfBirth,
				gender,
				eye,
				notes,
				odSphere,
				odCylinder,
				odAxis,
				odAcuity,
				osSphere,
				osCylinder,
				osAxis,
				osAcuity,
				testType,
				createdBy,
				// clearValues,
			} = testData;

			const data = {
				testId: testId,
				firstName: firstName,
				lastName: lastName,
				dateOfBirth: formatDOB(dateOfBirth),
				gender: gender,
				eye: eye,
				notes: notes,
				correction: {
					odSphere: odSphere,
					odCylinder: odCylinder,
					odAxis: odAxis,
					odAcuity: odAcuity,
					osSphere: osSphere,
					osCylinder: osCylinder,
					osAxis: osAxis,
					osAcuity: osAcuity,
				},
				testType: {
					value: testType.value,
					label: testType.label,
					disabled: testType.disabled,
					trajectories: testType.trajectories,
				},
				createdBy: createdBy,
			};

			const response = await testFetch.post("/tests", data);

			dispatch({ type: SAVE_TEST_SUCCESS });
			dispatch({ type: CLEAR_VALUES });
		} catch (error) {
			if (error.response.status === 401) return;
			dispatch({
				type: SAVE_TEST_ERROR,
				payload: { msg: error.response.data.msg },
			});
		}
		clearAlert();
	};

	const getTests = useCallback(async () => {
		let url = `tests`;
		dispatch({ type: GET_TESTS_BEGIN });
		try {
			const { data } = await testFetch.get(url);
			const { tests } = data;

			dispatch({
				type: GET_TESTS_SUCCESS,
				payload: {
					tests,
				},
			});
		} catch (error) {
			console.log(error.response);
		}
	}, [dispatch]); // assuming testFetch.get is stable and doesn't change

	const getTestTypes = async () => {
		let url = `test-types`;
		dispatch({ type: GET_TEST_TYPES_BEGIN });
		try {
			const { data } = await testFetch.get(url);
			const { testTypes } = data;

			dispatch({
				type: GET_TEST_TYPES_SUCCESS,
				payload: {
					testTypes,
				},
			});
		} catch (error) {
			console.log(error.response);
		}
	};

	const setupUser = async ({ currentUser, endPoint, alertText }) => {
		dispatch({ type: SETUP_USER_BEGIN });
		try {
			const { data } = await axios.post(`/api/v1/auth/${endPoint}`, currentUser);

			const { user } = data;
			dispatch({
				type: SETUP_USER_SUCCESS,
				payload: { user, alertText },
			});
		} catch (error) {
			dispatch({
				type: SETUP_USER_ERROR,
				payload: { msg: error.response.data.msg },
			});
		}
		clearAlert();
	};

	// const showReport = async (testId) => {
	// 	dispatch({ type: SHOW_REPORT_BEGIN });

	// 	let url = `reports/${testId}`;
	// 	const reportURL = await reportFetch
	// 		.get(url)
	// 		.then((data) => {
	// 			const file = new Blob([data], {
	// 				type: "application/pdf",
	// 			});
	// 			return URL.createObjectURL(file);
	// 		})
	// 		.catch((error) => {
	// 			console.error(error);
	// 		});
	// 	return reportURL;
	// };

	const showReport = async (testId) => {
		dispatch({ type: SHOW_REPORT_BEGIN });
	  
		let url = `reports/${testId}`;
		let reportURL;
	  
		try {
		  const data = await reportFetch.get(url);
		  const file = new Blob([data], { type: "application/pdf" });
		  reportURL = URL.createObjectURL(file);
		} catch (error) {
		  console.error(error);
		  // Here handle the error, for instance by updating some state
		  this.setState({ error: error.message });
		}
	  
		return reportURL;
	  };

	const handleSelect = async (event) => {
		dispatch({
			type: HANDLE_CHANGE,
			payload: event.target.value,
		});
	};

	return (
		<AppContext.Provider
			value={{
				...state,
				acquireData,
				getTests,
				getTestTypes,
				saveTest,
				clearValues,
				showReport,
				setupUser,
				handleChange,
				handleSelect,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

const useAppContext = () => {
	return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };
