import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import { useBackendStatusContext } from "../context/backendStatus/backendStatusContext";

import { MainLayout } from "./Layout";
import PageSpinner from "./PageSpinner";

const TestPage = React.lazy(() => import("../pages/TestPage"));
const ResultsPage = React.lazy(() => import("../pages/Results/Results"));
const UsersPage = React.lazy(() => import("../pages/Users"));
const SettingsPage = React.lazy(() => import("../pages/Settings"));
const SupportPage = React.lazy(() => import("../pages/Support"));
const AboutPage = React.lazy(() => import("../pages/About"));

const getBasename = () => {
    return `/${process.env.PUBLIC_URL.split("/").pop()}`;
};

const Router = () => {
    const backendStatus = useBackendStatusContext()
    const showBasicMenu = (!backendStatus.state.isAvailable || !backendStatus.state.isCertificateValid) && !backendStatus.state.isUpdating

    return (
        <BrowserRouter basename={getBasename()}>
            <Switch>
                <MainLayout>
                    <React.Suspense fallback={<PageSpinner />}>
                        {showBasicMenu ? (
                            <>
                                <Redirect exact to="/support" from="/"/>
                                <Redirect to="/support" from="/results"/>
                                <Redirect to="/support" from="/users"/>
                                <Redirect to="/support" from="/settings"/>
                            </>
                        ) : (
                            <>
                                {/*<Redirect to="/" />*/}
                            </>
                        )}
                        <Route exact path="/" component={TestPage} />
                        <Route exact path="/results" component={ResultsPage} />
                        <Route exact path="/users" component={UsersPage} />
                        <Route exact path="/settings" component={SettingsPage} />
                        <Route exact path="/support" component={SupportPage} />
                        <Route exact path="/about" component={AboutPage} />
                    </React.Suspense>
                </MainLayout>
            </Switch>
        </BrowserRouter>
    )
}

export default Router