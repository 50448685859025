import { makeStyles } from "@material-ui/core/styles";
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
	palette: {
		primary: {
			main: "#45A293",
		},
		secondary: {
			main: "#307066",
		},
		danger: {
			main: "#D6474B",
		},
		warning: {
			main: "#FF9933",
		},
		success: {
			main: "#4387B0",
		},
		disabled: {
			main: "#82B3AA"
		}
	},
});

const useStyle = makeStyles(() => ({
	root: {
		width: "auto",
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			// width: 600,
			marginLeft: "auto",
			marginRight: "auto",
		},
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.primary,
	},
	paper: {
		marginBottom: theme.spacing(2),
		marginBottom: theme.spacing(2),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginBottom: theme.spacing(6),
			padding: theme.spacing(4),
			padding: theme.spacing(4),
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.common.white,
		"&:hover": {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,
		},
		width: '50px',
		height: '40px',
		borderRadius: '5px',
		border: `1px solid ${theme.palette.primary.main}`,
		boxSizing: 'border-box',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	signOutButton: {
		color: theme.palette.common.white
	}
}));

export { theme, useStyle };
