import React, { useEffect } from "react";
import { Box, Modal, Typography, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { checkBackendStatus, updateBackend, fetchCompatibleVersionsData } from "../../actions/backendVersionActions";
import * as FaIcons from "react-icons/fa";

import { useBackendStatusContext } from "../../context/backendStatus/backendStatusContext";
import {
	SET_CERT_INVALID,
	SET_CERT_VALID,
	SET_BACKEND_UNAVAILABLE,
	SET_BACKEND_AVAILABLE,
	SET_BACKEND_VERSION,
	SET_UPDATING,
	SET_UPDATE_FINISHED,
	SET_UPDATE_REQUESTED,
	FETCH_COMPATIBLE_VERSIONS,
	FETCH_COMPATIBLE_VERSIONS_SUCCESS,
	FETCH_COMPATIBLE_VERSIONS_FAILURE,
} from "../../context/backendStatus/backendStatusActions";
import useCognito from "../../hooks/useCognito";
import AuthContext from "../../context/authContext";
import { Alert } from "@mui/material";

const UpdateModal = () => {
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		bgcolor: "background.paper",
		border: "2px #000",
		borderRadius: 2,
		boxShadow: 24,
		flexGrow: 1,
		padding: 5,
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
	};

	const backendStatus = useBackendStatusContext();
	const { signOut } = useCognito();
	// Access userData from AuthContext
	const { userData } = React.useContext(AuthContext);

	useEffect(() => {
		const updateStatus = async () => {
			// Skip update status check if user is part of "Reyedar-tester" group
			if (userData && userData.groups && userData.groups.includes("Reyedar-tester")) {
				return;
			}
			const backendVersion = await checkBackendStatus().catch((err) => {
				if ((err.response.status === "401") | (err.response.status === "403")) {
					signOut();
				}
			});

			if (backendVersion.isCertValid) backendStatus.dispatch({ type: SET_CERT_VALID });
			else backendStatus.dispatch({ type: SET_CERT_INVALID });

			if (backendVersion.isAvailable) backendStatus.dispatch({ type: SET_BACKEND_AVAILABLE });
			else backendStatus.dispatch({ type: SET_BACKEND_UNAVAILABLE });

			if (backendVersion.version)
				backendStatus.dispatch({ type: SET_BACKEND_VERSION, payload: { version: backendVersion.version } });

			try {
				const versionsData = await fetchCompatibleVersionsData();
				backendStatus.dispatch({
					type: FETCH_COMPATIBLE_VERSIONS_SUCCESS,
					payload: versionsData,
				});

				if (backendVersion.version === "Not able to read version.") {
					backendStatus.dispatch({ type: SET_UPDATING });
				} else if (backendStatus.state.isUpdating && backendVersion.version) {
					backendStatus.dispatch({
						type: SET_UPDATE_FINISHED,
						payload: {
							message: `Backend updated successfully to version: ${backendVersion.version}`,
							status: "Success",
						},
					});
				}
			} catch (error) {
				console.error("Error fetching compatible versions:", error);
				backendStatus.dispatch({
					type: FETCH_COMPATIBLE_VERSIONS_FAILURE,
					payload: error.message,
				});
			}
		};

		const interval = setInterval(
			() => {
				updateStatus();
			},
			backendStatus.state.isUpdating || !backendStatus.state.isAvailable ? 500 : 2000
		);

		return () => {
			clearInterval(interval);
		};
	}, [backendStatus, signOut]);

	const handleUpdate = () => {
		backendStatus.dispatch({ type: SET_UPDATE_REQUESTED });
		updateBackend();
	};

	const isModalOpen =
		backendStatus.state.isUpdating ||
		backendStatus.state.isUpdateRequired ||
		backendStatus.state.isBackendCompatibleWithFrontend === false; // explicitly check for false

	const renderContent = () => {
		if (backendStatus.state.isUpdating) {
			return (
				<>
					<Typography align={"center"} id="modal-modal-title" variant="h6" component="h1">
						Backend update in progress ...
					</Typography>
					<Alert severity="warning">You may be logged out</Alert>
					<CircularProgress />
				</>
			);
		} else if (!backendStatus.state.isBackendCompatibleWithFrontend) {
			return (
				<>
					<Typography align={"center"} id="modal-modal-title" variant="h6" component="h1">
						Backend incompatibility detected!
					</Typography>
					<Alert severity="error">
						The current backend is not compatible with this version of the frontend. Please contact the provider for
						further assistance.
					</Alert>
				</>
			);
		} else {
			return (
				<>
					<Typography align={"center"} id="modal-modal-title" variant="h6" component="h1">
						Backend update is required!
					</Typography>
					<LoadingButton
						disabled={!backendStatus.state.isBackendCompatibleWithFrontend}
						className="float-sm-end m-1"
						variant="outlined"
						startIcon={<FaIcons.FaDownload />}
						onClick={handleUpdate}
						loading={backendStatus.state.isUpdateRequested}
					>
						UPDATE NOW
					</LoadingButton>
				</>
			);
		}
	};

	return (
		<Modal
			open={isModalOpen}
			onClose={(event, reason) => {
				if (reason !== "backdropClick" && backendStatus.state.isBackendCompatibleWithFrontend !== false) {
					// close the modal only if it is not a click on the backdrop and the backend is compatible
				}
			}}
			aria-labelledby="modal-modal-title"
		>
			<Box sx={style}>{renderContent()}</Box>
		</Modal>
	);
};

export default UpdateModal;
