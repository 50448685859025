import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Avatar from '../Avatar';

const useStyles = makeStyles((theme) => ({
  card: {
    background: `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    color: theme.palette.common.white,
  },
  cardTitle: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  cardSubtitle: {
    fontSize: '1rem',
  },
}));

const UserCard = ({
  avatar,
  avatarSize,
  title,
  subtitle,
  text,
  children,
  className,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} {...restProps}>
      <CardContent className="d-flex justify-content-center align-items-center flex-column">
        <Avatar src={avatar} size={avatarSize} className="mb-2" />
        <Typography variant="h5" component="h2" className={classes.cardTitle}>
          {title}
        </Typography>
        <Typography className={classes.cardSubtitle}>{subtitle}</Typography>
        <Typography variant="body2">
          <small>{text}</small>
        </Typography>
      </CardContent>
      {children}
    </Card>
  );
};

UserCard.propTypes = {
  avatar: PropTypes.string,
  avatarSize: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

UserCard.defaultProps = {
  avatarSize: 80,
};

export default UserCard;