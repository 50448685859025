import React, { useState, useEffect } from "react";
import axios from "../../services/httpService/httpService";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider, styled, keyframes } from "@mui/material";
import { Typography, Stack, Box } from "@mui/material";
import useCognito from "../../hooks/useCognito";
import { fontSize } from "@mui/system";

const theme = createTheme({
	palette: {
		primary: {
			main: "#00b398",
		},
		secondary: {
			main: "#007864",
		},
		warning: {
			main: "#FF1A19",
		},
		starting: {
			main: "#FF9F19",
		},
	},
});

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RotatingIcon = styled(AutorenewIcon)({
	animation: `${rotate} 2s linear infinite`,
});

const UrlChecker = ({ circleSize, reverse, url, upAlias, downAlias }) => {
	const direction = reverse ? "row-reverse" : "row";

	const [progress, setProgress] = useState(0);
	const [status, setStatus] = useState("checking"); // 'checking', 'up', 'down', 'starting'
	const { signOut } = useCognito();

	useEffect(() => {
		let interval = setInterval(async () => {
			axios(url, { method: "GET" })
				.then((response) => {
					if (response.data.readiness === "starting") {
						const { readiness, progress: progressValue } = response.data;
						setStatus("starting");
						setProgress(progressValue);
					} else if (response.status === 200) {
						setStatus("up");
					} else {
						setStatus("down");
						setProgress(0);
					}
					if (response.status === 401 || response.status === 403) {
						signOut();
					}
				})
				.catch((error) => {
					setStatus("down");
					if (error.response && (error.response.status === 401 || error.response.status === 403)) {
						signOut();
					}
				});
		}, 1000);
		return () => {
			clearInterval(interval);
		};
	}, [url, signOut]);

	const renderStatusIndicator = () => {
		switch (status) {
			case "up":
				return <CheckCircleIcon style={{ fontSize: circleSize, color: theme.palette.primary.main }} />;
			case "down":
				return <ErrorIcon style={{ fontSize: circleSize, color: theme.palette.error.main }} />;
			case "starting":
				return (
					<CircularProgress
						variant="determinate"
						value={progress}
						style={{ color: theme.palette.starting.main }}
						size={circleSize - 2}
					/>
				);
			case "checking":
				return <RotatingIcon style={{ fontSize: circleSize, color: theme.palette.info.main }} />;
			default:
				return <AutorenewIcon style={{ fontSize: circleSize, color: theme.palette.info.main }} />;
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<Stack spacing={1} direction={direction} marginRight={1}>
				{renderStatusIndicator()}
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Typography
						style={{
							color:
								status === "down"
									? theme.palette.error.main
									: status === "starting"
									? theme.palette.starting.main
									: status === "checking"
									? theme.palette.info.main
									: theme.palette.primary.main,
						}}
					>
						SONDA device is{" "}
						{status === "up"
							? upAlias
							: status === "down"
							? downAlias
							: status === "starting"
							? "starting..."
							: "checking..."}
					</Typography>
				</Box>
			</Stack>
		</ThemeProvider>
	);
};

export default UrlChecker;
