import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@mui/material/styles";

import {
	Box,
	TextField,
	FormControl,
	FormLabel,
	FormGroup,
	InputLabel,
	OutlinedInput,
	Typography,
	Link,
	Button,
	Divider,
	IconButton,
} from "@mui/material";

import logoImage from "../images/logo192.png";
import PropTypes from "prop-types";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import axios from "axios";
import useCognito from "../hooks/useCognito";
import AuthContext from "../context/authContext";

const useStyles = makeStyles((theme) => ({
	centeredContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		maxWidth: "800px", // Set a maximum width to prevent the container from becoming too large
	},
	formGroup: {
		marginBottom: theme.spacing(3),
	},
	formControl: {
		width: "100%",
	},
	centeredText: {
		textAlign: "center",
		paddingBottom: theme.spacing(3),
	},
	linkContainer: {
		display: "flex",
		justifyContent: "flex-end",
	},
	rightAlignedText: {
		textAlign: "right",
	},
	error: {
		color: theme.palette.error.main,
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	logo: {
		width: 100,
		height: "auto",
	},
	submitButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
}));

const AuthForm = (props) => {
	//user inputs
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [passwordConfirm, setPasswordConfirm] = useState("");
	const [verificationCode, setVerificationCode] = useState("");

	//outputs
	const [userDetails, setUserDetails] = useState(null);
	const [state, setState] = useState(STATE_LOGIN);
	const [errorMessage, setErrorMessage] = useState("");

	const { setUserData, setIsAuthenticated } = useContext(AuthContext);
	const { userPool, signOut } = useCognito();

	const classes = useStyles();
	const theme = useTheme();

	const flushPasswords = () => {
		setPasswordConfirm("");
		setPassword("");
	};

	async function authenticateServer(jwt) {
		try {
			const headers = {
				"Content-Type": "application/json; charset=utf-8",
			};

			const response = await axios.post(
				"https://sonda-server.local:443/api/v1/auth/token",
				{ jwt: jwt },
				{
					withCredentials: true,
					headers: headers,
				}
			);

			// Checking if the JWT is valid
			if (response.status === 200) {
				const loginResponse = await axios.post(
					"https://sonda-server.local:443/api/v1/auth/login",
					{ jwt: jwt },
					{
						withCredentials: true,
						headers: headers,
					}
				);

				// Checking if login was successful
				if (loginResponse.status === 200) {
					const csrfToken = loginResponse.data.csrfToken;
					console.log("Authenticated, login token and cookie set");
					// Store the CSRF token in localStorage
					localStorage.setItem("csrfToken", csrfToken);

					// Update the user data in the AuthContext
					setUserData((prevData) => ({
						...prevData,
						groups: response.data.groups,
					}));
				} else {
					throw new Error("Login failed with status: " + loginResponse.status);
				}
			} else {
				throw new Error("JWT token validation failed with status: " + response.status);
			}

			// If all steps are successful, return true
			return true;
		} catch (error) {
			// Check if error has response and status property
			if (error.response && error.response.status) {
				console.error(
					`Error during authentication, server responded with status ${error.response.status}:`,
					error.message
				);
			} else {
				console.error("Error during authentication:", error.message);
			}

			// Propagate the error
			throw error;
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		setErrorMessage("");

		const authenticationData = {
			Username: username,
			Password: password,
		};
		const authDetails = new AuthenticationDetails(authenticationData);
		const userData = {
			Username: username,
			Pool: userPool,
		};
		const cognitoUser = new CognitoUser(userData);

		switch (state) {
			case STATE_LOGIN:
				cognitoUser.authenticateUser(authDetails, {
					onSuccess(result) {
						if (result) {
							console.log("onSuccess");
							// handle the Promise returned by authenticateServer
							authenticateServer(result.getIdToken().getJwtToken())
								.then(() => {
									console.log("onSuccess then");
									// if authenticateServer is successful
									// setUserData({ email: username });
									flushPasswords();
									setIsAuthenticated(true);
								})
								.catch((error) => {
									console.log("onSuccess error");
									// if authenticateServer fails
									console.error("Server authentication failed:", error);
									setErrorMessage(
										"Server authentication failed. Ensure that the Sonda Device is running and try again."
									);
									// sign Out
									signOut();
								});
						}
					},
					onFailure(err) {
						console.log(err);
						setErrorMessage(err.toString().split(": ").slice(1).join(". "));
					},
					newPasswordRequired(userAttributes) {
						delete userAttributes.email_verified;
						delete userAttributes.email;
						setUserDetails({
							user: cognitoUser,
							userAttr: userAttributes,
						});
						flushPasswords();
						setState(STATE_PASSWORD_CHANGE);
					},
				});
				break;
			case STATE_FORGOT_PASSWORD:
				cognitoUser.forgotPassword({
					onSuccess(result) {
						flushPasswords();
						setState(STATE_FORGOT_PASSWORD_CHANGE);
					},
					onFailure(err) {
						console.log(err);
						setErrorMessage(err.toString().split(": ").slice(1).join(". "));
					},
				});
				break;
			case STATE_FORGOT_PASSWORD_CHANGE:
				if (password === passwordConfirm) {
					cognitoUser.confirmPassword(verificationCode, password, {
						onSuccess() {
							flushPasswords();
							setState(STATE_LOGIN);
						},
						onFailure(err) {
							console.log(err);
							setErrorMessage(err.toString().split(": ").slice(1).join(". "));
						},
					});
				} else {
					setErrorMessage("The passwords do not match");
				}
				break;
			case STATE_PASSWORD_CHANGE:
				if (password === passwordConfirm) {
					userDetails.user.completeNewPasswordChallenge(password, userDetails.userAttr, {
						onSuccess(result) {
							if (result) {
								flushPasswords();
								setState(STATE_LOGIN);
							}
						},
						onFailure(err) {
							console.log(err);
							setErrorMessage(err.toString().split(": ").slice(1).join(". "));
						},
					});
				} else {
					setErrorMessage("The passwords do not match");
				}
				break;
			default:
				break;
		}
	};

	const renderButtonText = () => {
		const { buttonText } = props;

		if (buttonText) return buttonText;

		switch (state) {
			case STATE_PASSWORD_CHANGE:
			case STATE_FORGOT_PASSWORD_CHANGE:
				return "Change password";
			case STATE_FORGOT_PASSWORD:
				return "Reset my password";
			case STATE_LOGIN:
			default:
				return "Login";
		}
	};

	const renderForm = () => {
		switch (state) {
			case STATE_PASSWORD_CHANGE:
				return (
					<div className={classes.centeredContainer}>
						<Typography variant="h6">Select new password</Typography>
						<FormGroup className={classes.formGroup}>
							<FormControl fullWidth className={classes.formControl}>
								<TextField
									label={props.newPasswordLabel}
									id={props.newPasswordLabel}
									{...props.newPasswordInputProps}
									value={password}
									onChange={(event) => setPassword(event.target.value)}
								/>
							</FormControl>
						</FormGroup>
						<FormGroup className={classes.formGroup}>
							<FormControl fullWidth className={classes.formControl}>
								<TextField
									label={props.confirmPasswordLabel}
									id={props.confirmPasswordLabel}
									{...props.confirmPasswordInputProps}
									value={passwordConfirm}
									onChange={(event) => setPasswordConfirm(event.target.value)}
								/>
							</FormControl>
						</FormGroup>
					</div>
				);

			case STATE_FORGOT_PASSWORD_CHANGE:
				return (
					<>
						<FormLabel>Select new password</FormLabel>
						<FormGroup className={classes.formGroup}>
							<InputLabel htmlFor={props.verificationCodeLabel}>{props.verificationCodeLabel}</InputLabel>
							<OutlinedInput
								{...props.verificationCodeInputProps}
								value={verificationCode}
								onChange={(event) => setVerificationCode(event.target.value)}
							/>
						</FormGroup>
						<FormGroup className={classes.formGroup}>
							<InputLabel htmlFor={props.newPasswordLabel}>{props.newPasswordLabel}</InputLabel>
							<OutlinedInput
								{...props.newPasswordInputProps}
								value={password}
								onChange={(event) => setPassword(event.target.value)}
							/>
						</FormGroup>
						<FormGroup className={classes.formGroup}>
							<InputLabel htmlFor={props.confirmPasswordLabel}>{props.confirmPasswordLabel}</InputLabel>
							<OutlinedInput
								{...props.confirmPasswordInputProps}
								value={passwordConfirm}
								onChange={(event) => setPasswordConfirm(event.target.value)}
							/>
						</FormGroup>
					</>
				);

			case STATE_FORGOT_PASSWORD:
				return (
					<>
						<Typography variant="h6">
							Enter your Username below and we will send a message to reset your password
						</Typography>
						<Box component="form" noValidate>
							<FormGroup className={classes.formGroup}>
								<TextField
									label={props.usernameLabel}
									{...props.usernameInputProps}
									value={username}
									onChange={(event) => setUsername(event.target.value)}
									id={props.usernameLabel}
								/>
							</FormGroup>
						</Box>
					</>
				);

			case STATE_LOGIN:
			default:
				return (
					<>
						<Typography variant="h5" component="h2" className={classes.centeredText}>
							Sign in to your Account
						</Typography>
						<FormGroup className={classes.formGroup}>
							<TextField
								label={props.usernameLabel}
								id={props.usernameLabel}
								{...props.usernameInputProps}
								value={username}
								onChange={(event) => setUsername(event.target.value)}
							/>
						</FormGroup>
						<FormGroup className={classes.formGroup}>
							<TextField
								label={props.passwordLabel}
								id={props.passwordLabel}
								{...props.passwordInputProps}
								value={password}
								onChange={(event) => setPassword(event.target.value)}
								type="password"
							/>
						</FormGroup>
						<div className={classes.linkContainer}>
							<Link
								href="#"
								color="primary"
								underline="hover"
								className={classes.rightAlignedText}
								onClick={() => {
									setState(STATE_FORGOT_PASSWORD);
								}}
							>
								Forgot password?
							</Link>
						</div>
					</>
				);
		}
	};

	return (
		<div className={classes.centeredContainer}>
			<FormControl component="form" onSubmit={handleSubmit} className={classes.formControl}>
				{props.showLogo && (
					<Box display="flex" justifyContent="center" pb={4}>
						<IconButton onClick={props.onLogoClick}>
							<img src={logoImage} className={classes.logo} alt="logo" />
						</IconButton>
					</Box>
				)}
				<Box mb={2}>{renderForm()}</Box>
				{errorMessage && (
					<>
						<Divider />
						<FormLabel className={classes.error}>{errorMessage}</FormLabel>
					</>
				)}
				<Divider />
				<TextField type="submit" style={{ display: "none" }} />
				<Button
					variant="contained"
					size="large"
					className={classes.submitButton}
					fullWidth
					onClick={handleSubmit}
					color="primary"
					sx={{ backgroundColor: theme.palette.primary.main }}
				>
					{renderButtonText()}
				</Button>
				<Box className={classes.formGroup}>{props.children}</Box>
			</FormControl>
		</div>
	);
};

export const STATE_LOGIN = "LOGIN";
export const STATE_FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const STATE_FORGOT_PASSWORD_CHANGE = "FORGOT_PASSWORD_CHANGE";
export const STATE_PASSWORD_CHANGE = "PASSWORD_CHANGE";

AuthForm.propTypes = {
	showLogo: PropTypes.bool,
	usernameLabel: PropTypes.string,
	usernameInputProps: PropTypes.object,
	passwordLabel: PropTypes.string,
	passwordInputProps: PropTypes.object,
	newPasswordLabel: PropTypes.string,
	newPasswordInputProps: PropTypes.object,
	confirmPasswordLabel: PropTypes.string,
	confirmPasswordInputProps: PropTypes.object,
	verificationCodeLabel: PropTypes.string,
	verificationCodeInputProps: PropTypes.object,
};

AuthForm.defaultProps = {
	showLogo: true,
	usernameLabel: "Email",
	usernameInputProps: {
		type: "email",
		placeholder: "your@email.com",
	},
	passwordLabel: "Password",
	passwordInputProps: {
		type: "password",
		placeholder: "your password",
	},
	newPasswordLabel: "New password",
	newPasswordInputProps: {
		type: "password",
		placeholder: "New password",
	},
	confirmPasswordLabel: "Confirm password",
	confirmPasswordInputProps: {
		type: "password",
		placeholder: "confirm your password",
	},
	verificationCodeLabel: "Verification code",
	verificationCodeInputProps: {
		type: "password",
		placeholder: "Verification code",
	},
	onLogoClick: () => {},
};

export default AuthForm;
