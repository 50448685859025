import Avatar from "../../components/Avatar";
import { UserCard } from "../../components/Card";
import React, { useState, useContext } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { MdClearAll } from "react-icons/md";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import {
	AppBar,
	Button,
	Toolbar,
	IconButton,
	Popover,
} from "@material-ui/core";

import bn from "../../utils/bemnames";

import useCognito from "../../hooks/useCognito";
import AuthContext from "../../context/authContext";

import UrlChecker from "../HealthCheck/UrlChecker";

import { theme, useStyle } from "./styles";
import UpdateButton from "../UpdateButton";
import { useBackendStatusContext } from "../../context/backendStatus/backendStatusContext";

const bem = bn.create("header");

const Header = () => {
	const classes = useStyle();
	const { signOut } = useCognito();
	const { userData } = useContext(AuthContext);
	const backendStatus = useBackendStatusContext()

	const [state, setState] = useState({
		isOpenNotificationPopover: false,
		isNotificationConfirmed: false,
		isOpenUserCardPopover: false,
		currentUser: userData.email,
	});

	const toggleNotificationPopover = () => {
		setState((prevState) => {
			return {
				...prevState,
				isOpenNotificationPopover: !prevState.isOpenNotificationPopover,
			};
		});

		if (state.isNotificationConfirmed) {
			setState((prevState) => {
				return {
					...prevState,
					isNotificationConfirmed: true,
				};
			});
		}
	};

	const toggleUserCardPopover = () => {
		setState((prevState) => {
			return {
				...prevState,
				isOpenUserCardPopover: !prevState.isOpenUserCardPopover,
			};
		});
	};

	const handleSidebarControlButton = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const sidebar = document.querySelector(".ss-sidebar");
		if (sidebar) {
			sidebar.classList.toggle("ss-sidebar--open");
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<AppBar position="static" className={bem.b("bg-white")}>
				<Toolbar className="d-flex justify-content-between">
					<div>
						<IconButton
							onClick={handleSidebarControlButton}
							className={classes.menuButton}
						>
							<MdClearAll size={25} />
						</IconButton>
					</div>

					<div>
						<div className="d-flex align-items-center">
							{backendStatus.state.isUpdatePossible && <UpdateButton/>}
							<UrlChecker
								url="https://sonda-server.local:443/readiness"
								reverse={true}
								circleSize={40}
								upAlias="READY"
								downAlias="NOT READY"
							/>
							<Avatar
								onClick={toggleUserCardPopover}
								className="can-click ml-3"
							/>
							<div>
								<Popover
									open={state.isOpenUserCardPopover}
									onClose={toggleUserCardPopover}
									anchorEl={document.getElementById("Popover2")}
									anchorOrigin={{ vertical: "top", horizontal: "right" }}
									transformOrigin={{ vertical: "top", horizontal: "right" }}
									PaperProps={{ style: { minWidth: 250, marginTop: "40px" } }}
								>
									<UserCard
										// className="p-0 border-light"
										subtitle={state.currentUser}
										// text="Last updated 3 mins ago"
										text=""
									>
										<div
											style={{
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<div>
												<Button color="white"
													// className={classes.button}
													startIcon={<ExitToAppIcon className={classes.signOutButton}/>}
													onClick={() => signOut()}
													className={classes.signOutButton}
												>
													Sign Out
												</Button>
											</div>
										</div>
									</UserCard>
								</Popover>
							</div>
						</div>
					</div>
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
};

export default Header;
