import AuthForm from "../components/AuthForm";
import React, { useCallback, useState } from "react";
import { Button, Card, Grid, Typography } from "@mui/material";
import CertificateModal from "../components/CertificateModal/CertificateModal";
import appInfo from "../../package.json";

const AuthPage = () => {
	const [isCertificateModalOpen, setIsCertificateModalOpen] = useState(false);

	const env = process.env.REACT_APP_ENV;
	const shouldDisplayVersion = env === "develop" || env === "testing";

	const openCertificateModal = useCallback(() => {
		setIsCertificateModalOpen(true);
	}, []);

	const handleClose = useCallback(() => {
		setIsCertificateModalOpen(false);
	}, []);

	return (
		<Grid container spacing={0} alignItems="center" justifyContent="center" style={{ minHeight: "100vh" }}>
			<Grid item xs={12} sm={8} md={6} lg={4}>
				<Card variant="outlined" style={{ padding: "2rem" }}>
					<CertificateModal isOpen={isCertificateModalOpen} onClose={handleClose} />
					<AuthForm />
					<div style={{ display: "flex", justifyContent: "center" }}>
						<Button onClick={openCertificateModal}>Download certificate</Button>
					</div>
				</Card>
				<div style={{ marginTop: "2rem", textAlign: "center" }}>
					{shouldDisplayVersion && (
						<div
							style={{
								marginTop: "1rem",
								textAlign: "center",
								fontSize: "0.75rem",
								color: "grey",
							}}
						>
							v{appInfo.version}
						</div>
					)}
				</div>
			</Grid>
		</Grid>
	);
};

export default AuthPage;
