import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fafafa',
    padding: '1rem',
    textAlign: 'center',
    marginTop: 'auto'
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="body1" color="textSecondary">
        © 2023 Reperio B.V.
      </Typography>
    </div>
  );
};

export default Footer;
