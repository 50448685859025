import { createContext } from "react";

const AuthContext = createContext({
    isAuthenticated: false,
    setIsAuthenticated: (auth) => {},
    userData: { email: '', groups: []},
    setUserData: (user) => {},
    csrfToken: null, // csrfToken state
    setCsrfToken: (token) => {}, // setCsrfToken function
});

export default AuthContext;