import React, { useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { ErrorContext } from "../context/errorContext";

export default function ErrorSnackbar() {
	const { error, clearError } = useContext(ErrorContext);

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		clearError();
	};

	return (
		<Snackbar open={!!error} autoHideDuration={6000} onClose={handleClose}>
			<Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
				{error?.message}
			</Alert>
		</Snackbar>
	);
}
