import React from "react";
import { ErrorContext } from "../context/errorContext";

class ErrorBoundary extends React.Component {
	static contextType = ErrorContext;

	componentDidCatch(error, errorInfo) {
		this.context.setError(error);
	}

	render() {

		if (this.context.error) {

		}

		return this.props.children;
	}
}

export default ErrorBoundary;
