import React, { useState, useEffect, useContext } from "react";

import AuthPage from "./pages/AuthPage";
import AuthContext from "./context/authContext";
import useCognito from "./hooks/useCognito";
import { BackendStatusProvider } from "./context/backendStatus/backendStatusContext";
import { ErrorContext } from "./context/errorContext";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorSnackbar from "./components/ErrorSnackbar";

import Router from "./components/Router";
import UpdateModal from "./components/UpdateModal/UpdateModal";

import useIdleTimer from "./hooks/useIdleTimer";
import useActivityMonitor from "./hooks/useActivityMonitor";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		primary: {
			main: "#45A293",
		},
		secondary: {
			main: "#A1C7E0",
		},
		danger: {
			main: "#D6474B",
		},
		warning: {
			main: "#FF9933",
		},
		success: {
			main: "#0099DD",
		},
	},
});

export default function App() {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [userData, setUserData] = useState({ email: "", groups: []});
	const { getAuthenticatedUser, signOut } = useCognito();
	const [csrfToken, setCsrfToken] = useState(null);
	const { error, setError, clearError } = useContext(ErrorContext);

	const onIdle = () => {
		if (isAuthenticated) {
			setIsAuthenticated(false);
			signOut();
		}
	};

	const keepAliveAction = async () => {
		console.log("Keeping session alive");
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/${process.env.REACT_APP_AUTH_SERVICE_API_VERSION}/keep-alive`,
			{
				method: "POST",
				credentials: "include",
				headers: {
					"Content-Type": "application/json; charset=utf-8",
					"Cache-Control": "no-cache, no-store, must-revalidate",
					"X-CSRF-Token": localStorage.getItem("csrfToken"),
				},
			}
		);

		if (!response.ok) {
			throw new Error(`Error: ${response.statusText}`);
		}
		return await response.json();
	};

	const idleDuration = 60 * 60 * 1000;
	const keepAliveInterval = 10 * 1000;

	useIdleTimer(idleDuration, onIdle);
	useActivityMonitor(idleDuration, onIdle, keepAliveInterval, keepAliveAction);

	useEffect(() => {
		getAuthenticatedUser(); //load user

		window.addEventListener("error", handleError);
		window.addEventListener("unhandledrejection", handlePromiseRejection);

		return () => {
			window.removeEventListener("error", handleError);
			window.removeEventListener("unhandledrejection", handlePromiseRejection);
		};
	}, [getAuthenticatedUser]);

	const handleError = (event) => {
		// Check if the error object has a message property. If not, use a generic message
		const errorMessage = event.error?.message || "An unexpected error occurred.";
		setError(new Error(errorMessage));
	};

	const handlePromiseRejection = (event) => {
		// Check if the reason object has a message property. If not, use a generic message
		const errorMessage = event.reason?.message || "An unexpected error occurred.";
		setError(new Error(errorMessage));
		event.preventDefault();
		
		//remove this line
	};

	return (
		<AuthContext.Provider
			value={{ isAuthenticated, setIsAuthenticated, userData, setUserData, csrfToken, setCsrfToken }}
		>
			<ErrorContext.Provider value={{ error, setError, clearError }}>
				<BackendStatusProvider>
					<ErrorSnackbar />
					{!isAuthenticated ? (
						<ThemeProvider theme={theme}>
							<AuthPage hideSighUp={true} />
						</ThemeProvider>
					) : (
						<ErrorBoundary>
							<UpdateModal />
							<Router />
						</ErrorBoundary>
					)}
				</BackendStatusProvider>
			</ErrorContext.Provider>
		</AuthContext.Provider>
	);
}
