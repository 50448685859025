import { CognitoUserPool } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';

// Configuration constants
const USER_POOL_ID = 'eu-west-1_7BO1CKjGV';
const CLIENT_ID = '42a499ub844nmp8kad58l82cfq';
const REGION = 'eu-west-1';
const IDENTITY_POOL_ID = 'eu-west-1:585a74f2-c8f9-4324-b4cb-419225ebae38';
const BUCKET_NAME = 'sonda-app-certs';
const FILE_KEY = 'compatibility-matrix.json';

// Initialize the AWS configuration
AWS.config.region = REGION;
AWS.config.credentials = new AWS.CognitoIdentityCredentials({ IdentityPoolId: IDENTITY_POOL_ID });

// Helper function to retrieve the Cognito User Pool
const getUserPool = () => new CognitoUserPool({ UserPoolId: USER_POOL_ID, ClientId: CLIENT_ID });

// Helper function to get current Cognito user session
const getCurrentUserSession = () => {
    const userPool = getUserPool();
    const cognitoUser = userPool.getCurrentUser();
    
    return new Promise((resolve, reject) => {
      if (!cognitoUser) {
        reject(new Error('No current user'));
      } else {
        cognitoUser.getSession((err, session) => {
          if (err) {
            reject(err);
          } else if (session.isValid()) {
            resolve(session);
          } else {
            reject(new Error('Session is invalid'));
          }
        });
      }
    });
  };
  

// Helper function to refresh AWS credentials using the Cognito session
const refreshAWSCredentials = async (session) => {
  const loginKey = `cognito-idp.${REGION}.amazonaws.com/${USER_POOL_ID}`;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IDENTITY_POOL_ID,
    Logins: { [loginKey]: session.getIdToken().getJwtToken() },
  });

  await AWS.config.credentials.getPromise();
};

// Function to safely parse JSON content
const parseJSON = (content) => {
  try {
    return JSON.parse(content);
  } catch (error) {
    throw new Error('Failed to parse JSON content');
  }
};

// Function to fetch compatible versions from S3
export const compatibleVersions = async () => {
  try {
    // Retrieve and set up the user's AWS credentials
    const session = await getCurrentUserSession();
    await refreshAWSCredentials(session);

    // Create an S3 service object
    const s3 = new AWS.S3();
    const params = { Bucket: BUCKET_NAME, Key: FILE_KEY };

    // Fetch the object from S3
    const data = await s3.getObject(params).promise();

    // Parse and return the compatibility matrix
    return parseJSON(data.Body.toString('utf-8'));
  } catch (error) {
    console.error('Error fetching the compatible versions:', error.message);
    throw error;
  }
};
