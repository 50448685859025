// Add a leading zero if the value is less than 10
function padTo2Digits(num) {
	return num.toString().padStart(2, "0");
}

// Format date of birth
function formatDOB(date) {
	const newDate = [
		padTo2Digits(date.getMonth() + 1),
		padTo2Digits(date.getDate()),
		date.getFullYear(),
	].join("/")

	return new Date(newDate).getTime();
}

export { formatDOB };
